import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ICreateServerProgress,
  ICreateServerStatus,
  IGameOptions,
  IUserServer,
} from '../models/ServerType';
import { getCSRFToken } from '../utils/token';

export const userServersApi = createApi({
  reducerPath: 'userServers',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api/userserver`,
    credentials: 'include', // Tried here
    prepareHeaders: (headers, api) => {
      if (api.type === 'mutation') {
        headers.set('X-CSRFToken', <string>getCSRFToken());
      }

      return headers;
    },
  }),
  tagTypes: ['IUserServer', 'IUserGameMetrics', 'IUserAvailableGame'],
  endpoints: (build) => ({
    getUserServers: build.query<IUserServer[], void>({
      query: () => '/',
      transformResponse: (servers: IUserServer[]) => {
        if (Array.isArray(servers)) {
          servers = servers.filter((server) => !!server.info?.game_id);
        }

        return servers;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'IUserServer' as const,
                id,
              })),
              { type: 'IUserServer' },
            ]
          : [{ type: 'IUserServer' }],
    }),
    stopServer: build.mutation<null, string>({
      query: (id) => ({
        url: `/${id}/stop/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [
        {
          type: 'IUserServer',
          id,
        },
        {
          type: 'IUserServer',
        },
      ],
    }),
    startServer: build.mutation<null, string>({
      query: (id) => ({
        url: `/${id}/start/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, id) => [
        {
          type: 'IUserServer',
          id,
        },
        {
          type: 'IUserServer',
        },
      ],
    }),
    destroyServer: build.mutation<null, string>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        {
          type: 'IUserServer',
          id,
        },
        {
          type: 'IUserServer',
        },
      ],
    }),
    createServer: build.mutation<ICreateServerStatus, IGameOptions>({
      query: (options: IGameOptions) => ({
        url: '/',
        method: 'POST',
        body: options,
      }),
      invalidatesTags: (result, error, game_id) => [
        {
          type: 'IUserServer',
          game_id,
        },
        {
          type: 'IUserServer',
        },
      ],
    }),
    createServerProgress: build.query<ICreateServerProgress, string>({
      query: (id) => `/${id}/progress/`,
    }),
  }),
});

export const getManageLink = (gameId: string) =>
  `${process.env.REACT_APP_BACKEND_URL}/api/userserver/${gameId}/manage/`;

export const {
  useStartServerMutation,
  useCreateServerMutation,
  useCreateServerProgressQuery,
  useGetUserServersQuery,
  useStopServerMutation,
  useDestroyServerMutation,
} = userServersApi;
