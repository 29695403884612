import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: '700',
    color: 'white',
    textTransform: 'uppercase',
  },
  sizes: {
    sm: {
      letterSpacing: '1.5px',
      fontWeight: 700,
    },
    md: {
      letterSpacing: '2.5px',
      fontSize: '17px',
      fontWeight: 700,
      px: '35px',
      marginRight: '40px',
      height: '52px;',
      minWidth: '166px',
    },
    lg: {
      letterSpacing: '2.5px',
      height: '70px',
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  variants: {
    'ghost': {
      borderColor: 'brand.ghost-light',
      borderWidth: '2px',
      borderRadius: 0,
      color: 'brand.ghost-light',
      bg: 'rgba(74, 62, 101, 0.1)',
      _hover: {
        color: 'brand.ghost-light',
        bg:
          'radial-gradient(circle at 50% -60%, rgba(var(--chakra-colors-brand-ghost-light-n), 0.45) 20%, rgba(0, 0, 0, 0) 60%), ' +
          'rgba(var(--chakra-colors-brand-ghost-n), 0.35)',
      },
      _active: {
        bg: 'brand.ghost-light',
      },
    },
    'ghost-gray': {
      borderColor: 'brand.gray-border',
      borderWidth: '2px',
      borderRadius: 0,
      color: 'white',
      bg: 'rgb(var(--chakra-colors-brand-gray-bg-n))',
      _hover: {
        bg:
          'radial-gradient(circle at 50% -60%, rgba(255,255,255 , 0.1 5) 20%, rgba(0, 0, 0, 0) 60%), ' +
          'rgba(var(--chakra-colors-brand-gray-bg-n), 1)',
      },
      _active: {
        bg: 'brand.ghost-light',
      },
    },
    'ghost-third': {
      borderColor: 'brand.ghost-light',
      borderWidth: '2px',
      borderRadius: 0,
      color: 'white',
      bg:
        'radial-gradient(circle at 50% -60%, rgba(var(--chakra-colors-brand-ghost-light-n), 0.45) 20%, rgba(0, 0, 0, 0) 60%), ' +
        'rgba(var(--chakra-colors-brand-ghost-n), 0.65)',
      _hover: {
        bg: 'brand.ghost-light',
      },
      _active: {
        bg: 'brand.ghost-light',
      },
    },
    'secondary': {
      fontFamily: "'SF Pro Display', '-apple-system', 'system-ui', 'Arial', sans-serif",
      borderRadius: 0,
    },
    'default': {
      borderColor: 'brand.primary',
      borderWidth: '2px',
      borderRadius: 0,
      color: 'brand.primary',
      bg: 'rgb(var(--chakra-colors-brand-button-bg))', // '',
      _hover: {
        bg:
          'radial-gradient(circle at 50% -30%, rgba(var(--chakra-colors-brand-button-hover-bg), 0.35) 10%, rgba(0, 0, 0, 0) 90%),' +
          ' rgb(var(--chakra-colors-brand-button-bg))',
      },
      _active: {
        bg: 'brand.secondary',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
});

export default Button;
