import { Box, Flex, Image } from '@chakra-ui/react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';
import { setInnerHtml } from '../../utils/html.utils';

export const Footer = () => {
  const { t } = useTranslation('footer');
  return (
    <footer className="footer">
      <Flex
        gap={20}
        marginBottom="80px"
        flexDirection={{
          'base': 'column',
          'sm': 'row',
          'md': 'row',
          'lg': 'row',
          'xl': 'row',
          '2xl': 'row',
        }}>
        <Box flexGrow={1}>
          <b>{t('products')}</b>
          <li>
            <a href="https://www.opera.com/gx">Opera GX</a>
          </li>
          <li>
            <a href="https://www.opera.com/gx#mobile">Opera GX Mobile</a>
          </li>
          <li>
            <a href="https://www.yoyogames.com/en/gamemaker">Game Maker</a>
          </li>
        </Box>
        <Box flexGrow={10}>
          <b>{t('legal.title')}</b>
          <li>
            <a href="https://legal.opera.com/terms/">{t('legal.tos')}</a>
          </li>
          <li>
            <a href="https://legal.opera.com/cookies/">{t('legal.cookie')}</a>
          </li>
          <li>
            <a href="https://legal.opera.com/privacy/">{t('legal.privacy')}</a>
          </li>
          <li>
            <a href="https://security.opera.com">{t('legal.security')}</a>
          </li>
          <li>
            <a href="/GX%20Servers%20Withdrawal%20Form.pdf" download>
              {t('legal.withdrawal_form')}
            </a>
          </li>
        </Box>

        <Box flexGrow={1} className="logos">
          <li>
            <Image src="/assets/images/GX-Logo.svg" />
          </li>
          <hr />
          <li>Follow us</li>
          <div className="social">
            <a href="https://opr.as/footer-discord" aria-label="Discord">
              <Image src="/assets/images/footer/discord.png" />
            </a>
            <a href="https://opr.as/footer-reddit" aria-label="Reddit">
              <Image src="/assets/images/footer/reddit.png" />
            </a>
            <a href="https://opr.as/footer-twitch" aria-label="Twitch">
              <Image src="/assets/images/footer/twitch.png" />
            </a>
            <a href="https://opr.as/footer-twitter" aria-label="Twitter">
              <Image src="/assets/images/footer/twitter.png" />
            </a>
          </div>
        </Box>
      </Flex>
      <hr />
      <div className="copyrights">
        <div {...setInnerHtml(t('copyright1'))} />
        <div>{t('copyright2')}</div>
      </div>
    </footer>
  );
};

export default Footer;
