import { defineStyleConfig } from '@chakra-ui/react';

export const Radio = defineStyleConfig({
  // parts:     ['label'],
  baseStyle: {
    control: {
      borderWidth: '1',
      width: '14px;',
      height: '14px;',
      zIndex: 1,
      _checked: {
        borderColor: 'brand.primary',
        color: 'brand.primary',
        background: '',
        borderWidth: '1px',
      },
    },
  },
  variants: {
    map: {
      control: {
        borderColor: 'white',
        borderWidth: '1px',
        _before: {
          width: '50%',
          height: '50%',
          background: 'white',
          borderRadius: '5px',
        },
        _hover: {
          _before: {
            width: '0%',
            height: '0%',
          },
        },
        _checked: {
          _hover: {
            background: 'rgba(var(--chakra-colors-brand-primary-n), 0.75)',
          },
          background: 'rgba(0,0,0,0)',
          _disabled: {
            background: 'rgba(0,0,0,0)',
            borderColor: 'rgba(var(--chakra-colors-brand-primary-n), 0.75)',
            borderWidth: '1px',
            opacity: '0.9',
            _before: {
              borderColor: 'rgba(var(--chakra-colors-brand-primary-n), 0.75)',
            },
          },
        },
        _disabled: {
          background: 'rgba(0,0,0,0)',
          borderColor: 'white',
          borderWidth: '1px',
          opacity: '0.2',
        },
      },
    },
  },
});

export default Radio;
