import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Link,
  Menu as ChakraMenu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { ROUTES } from '../../utils/routes';
import { useAuthContext } from '../../context/AuthorizationContext';
import { IUser, LoggedStatus } from '../../models/User';
import { setInnerHtml } from '../../utils/html.utils';
import './Menu.scss';
import { AuthorizationModel } from '../../models/AuthorizationModel';
import { getLang, setLang } from '../../i18n/lang';
import { SUPPORTED_LANGS } from '../../i18n/i18n';
import '@fontsource/inter';
import { Analytics, GAEvent, GAEventType } from '../../utils/Analytics';

export const Menu = () => {
  const { login, logout, status, user } = useAuthContext();
  const { t } = useTranslation('menu');
  const langs = SUPPORTED_LANGS.map((lang) => ({
    id: lang,
    label: t(`lang.${lang}`),
  }));

  // i18n.changeLanguage('de');
  const {
    isOpen: isSupportPopupOpen,
    onOpen: onSupportPopupOpen,
    onClose: onSupportPopupClose,
  } = useDisclosure();
  const cancelRef = useRef(null);
  // const {
  //   data: servers,
  //   isLoading,
  //   error,
  // } = useGetServerTypesQuery();
  // useEffect(() => {
  //   if (user?.subscription?.servers && servers !== undefined) {
  //     setCurrentServer(servers.find((server) => server.id === user?.subscription?.servers[0]));
  //   }
  // }, [servers, isLoading, error, user?.subscription]);

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const getServerNamesString = (details: { [serverName: string]: string }) =>
    Object.keys(details).map((el) => (
      <h5 className="server_name" key={`server_${el.toString()}`}>
        {capitalizeFirstLetter(el)}
        <span>{el[0]}</span>
      </h5>
    ));

  const getNextPaymentString = (details: { [serverName: string]: string }) => {
    const list = Object.keys(details).map(
      (key) => `${capitalizeFirstLetter(key)}: ${details[key.toString()]}`,
    );
    return list.map((el) => (
      <span key={`paymen_${el}`}>
        {el}
        <br />
      </span>
    ));
  };

  const getSubscriptionMenuItem = () => (
    <>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <div
            className="right_menu_el subscription_link"
            onClick={() => {
              Analytics.getInstance().sendEvent(
                GAEvent(GAEventType.subscriptionMenuClick),
              );
            }}>
            {t('subscription_link')}
          </div>
        </PopoverTrigger>
        <Portal>
          <PopoverContent className="popover_content subscription">
            <PopoverBody>
              <h5>{t('subscription.title')}</h5>
              <hr />
              {user?.subscription?.details && (
                <>
                  {getServerNamesString(user?.subscription.details)}
                  <p>{t('subscription.plan')}</p>
                  <br />
                </>
              )}

              {user?.subscription?.details && (
                <h5>{getNextPaymentString(user?.subscription.details)}</h5>
              )}
              <p>{t('subscription.next_payment')}</p>
              {user?.subscription?.upgrade_link && (
                <Link
                  className="nohover"
                  onClick={() =>
                    Analytics.getInstance().sendEvent(
                      GAEvent(GAEventType.subscriptionUpgrade),
                    )
                  }
                  isExternal
                  href={user.subscription.upgrade_link}
                  rel="noreferrer">
                  <Button size="sm">{t('subscription.upgrade')}</Button>
                </Link>
              )}
              <Link
                className="nohover"
                onClick={() =>
                  Analytics.getInstance().sendEvent(
                    GAEvent(GAEventType.subscriptionManage),
                  )
                }
                isExternal
                href={user?.subscription?.manage_link}
                rel="noreferrer">
                <Button size="sm" variant="ghost">
                  {t('subscription.manage')}
                </Button>
              </Link>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );

  const getUserMenuItem = (u: IUser) => (
    <>
      <Popover
        strategy="fixed"
        placement="bottom-end"
        onClose={() => {
          console.log(this);
        }}>
        <PopoverTrigger>
          <div
            className="right_menu_el avatar"
            onClick={() => {
              Analytics.getInstance().sendEvent(GAEvent(GAEventType.userMenuClick));
            }}>
            <span>{u.fullname ? u.fullname : u.email}</span>
            <Image
              verticalAlign="middle"
              src={
                u.profile_image_url ? u.profile_image_url : '/assets/images/avatar.svg'
              }
            />
          </div>
        </PopoverTrigger>
        <Portal>
          <PopoverContent className="popover_content">
            <PopoverBody>
              <h5>{t('avatar.title')}</h5>
              <hr />
              <Center>
                <Image
                  src={
                    u.profile_image_url
                      ? u.profile_image_url
                      : '/assets/images/avatar.svg'
                  }
                />
              </Center>
              <h5>{u.fullname}</h5>
              <p>{u.email}</p>
              <Link
                className="nohover"
                target="_blank"
                onClick={() => {
                  Analytics.getInstance().sendEvent(GAEvent(GAEventType.profileClick));
                }}
                href={u.profile_link}>
                <Button size="sm">{t('avatar.manage')}</Button>
              </Link>
              <Button
                onClick={() => {
                  onSupportPopupOpen();
                  Analytics.getInstance().sendEvent(GAEvent(GAEventType.helpClick));
                }}
                size="sm"
                variant="ghost">
                {t('avatar.support')}
              </Button>
              <Button size="sm" onClick={logout} variant="ghost">
                {t('avatar.logout')}
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <AlertDialog
        isOpen={isSupportPopupOpen}
        leastDestructiveRef={cancelRef}
        onClose={onSupportPopupOpen}
        isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <p {...setInnerHtml(t('support.content'))} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack width="100%" justifyContent="center">
                <Link
                  ml={3}
                  width="30%"
                  className="nohover"
                  href="mailto:gx-servers@support.opera.com">
                  <Button size="sm" width="100%">
                    {t('support.email_button')}
                  </Button>
                </Link>
                <Button
                  size="sm"
                  variant="ghost"
                  ml={3}
                  width="30%"
                  ref={cancelRef}
                  onClick={onSupportPopupClose}>
                  {t('support.cancel_button')}
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );

  const getLangMenu = () => (
    <ChakraMenu>
      <MenuButton
        className="lang-button"
        onClick={() => {
          Analytics.getInstance().sendEvent(GAEvent(GAEventType.langMenuClick));
        }}>
        {getLang()}
        <Image src="/assets/images/menu-icon.svg" />
      </MenuButton>
      <MenuList
        className="lang-menu"
        minWidth="20px"
        onClick={(e) => {
          // @ts-ignore
          if (e && e.target?.dataset?.itemLang) {
            // @ts-ignore
            setLang(e.target.dataset?.itemLang);
          }
        }}>
        {langs.map((lang) => (
          <MenuItem key={lang.label} data-item-lang={lang.id}>
            {lang.label}
          </MenuItem>
        ))}
      </MenuList>
    </ChakraMenu>
  );
  return (
    <Center>
      <Flex alignContent="center" className="menu menu-mobile" hidden={!isMobile}>
        <Box className="left_menu">
          <a
            href="/"
            aria-label="GX Logo"
            onClick={() => {
              Analytics.getInstance().sendEvent(GAEvent(GAEventType.burgerMenuClick));
            }}>
            <Image src="/assets/images/GX-Logo.svg" />
          </a>
        </Box>

        <Box flexGrow={1} className="right_menu">
          {getLangMenu()}
          <ChakraMenu closeOnBlur={false}>
            <MenuButton
              className="burger-button"
              onClick={() => {
                Analytics.getInstance().sendEvent(GAEvent(GAEventType.burgerMenuClick));
              }}>
              <Image src="/assets/images/burger-menu.svg" />
            </MenuButton>
            <MenuList className="burger-menu">
              {!AuthorizationModel.isActiveSubscription(user) && (
                <>
                  <MenuItem key="home">
                    <ScrollLink duration={2000} smooth="easeInOutQuint" to={ROUTES.HOME}>
                      {t(`items.${ROUTES.HOME}`)}
                    </ScrollLink>
                  </MenuItem>
                  <MenuItem key="how">
                    <ScrollLink
                      duration={2000}
                      smooth="easeInOutQuint"
                      to={ROUTES.HOW_IT_WORKS}>
                      {t(`items.${ROUTES.HOW_IT_WORKS}`)}
                    </ScrollLink>
                  </MenuItem>
                  <MenuItem key="subscription">
                    <ScrollLink
                      duration={2000}
                      smooth="easeInOutQuint"
                      to={ROUTES.SUBSCRIPTION}>
                      {t(`items.${ROUTES.SUBSCRIPTION}`)}
                    </ScrollLink>
                  </MenuItem>
                </>
              )}
              {status === LoggedStatus.NON_LOGGED && (
                <MenuItem key="sign_in" onClick={() => login()}>
                  {t('sign_in_button')}
                </MenuItem>
              )}
              {status === LoggedStatus.LOGGED &&
                AuthorizationModel.isActiveSubscription(user) && (
                  <MenuItem key="games">
                    <Link as={ReactLink} to={ROUTES.GAMES}>
                      {t(`items.${ROUTES.GAMES}`)}
                    </Link>
                  </MenuItem>
                )}
              {status === LoggedStatus.LOGGED &&
                AuthorizationModel.isActiveSubscription(user) && (
                  <div className="simple-menu-item">{getSubscriptionMenuItem()}</div>
                )}
              {status === LoggedStatus.LOGGED && user && (
                <div className="simple-menu-item">{getUserMenuItem(user)}</div>
              )}
            </MenuList>
          </ChakraMenu>
        </Box>
      </Flex>
      <Flex alignContent="center" className="menu" gap={5} width="80vw" hidden={isMobile}>
        <Box className="left_menu">
          <a aria-label="GX Logo" href="/">
            <Image src="/assets/images/GX-Logo.svg" />
          </a>
        </Box>
        {!AuthorizationModel.isActiveSubscription(user) && (
          <Box flexGrow={2} className="center_menu">
            <HStack spacing="10px" height="100%" justifyContent="center">
              <Flex>
                <ScrollLink duration={2000} smooth="easeInOutQuint" to={ROUTES.HOME}>
                  {t(`items.${ROUTES.HOME}`)}
                </ScrollLink>
                <ScrollLink
                  duration={2000}
                  smooth="easeInOutQuint"
                  to={ROUTES.HOW_IT_WORKS}>
                  {t(`items.${ROUTES.HOW_IT_WORKS}`)}
                </ScrollLink>
                <ScrollLink
                  duration={2000}
                  smooth="easeInOutQuint"
                  to={ROUTES.SUBSCRIPTION}>
                  {t(`items.${ROUTES.SUBSCRIPTION}`)}
                </ScrollLink>
              </Flex>
            </HStack>
          </Box>
        )}

        <Box flexGrow={1} className="right_menu">
          <HStack spacing="20px" textAlign="right" justifyContent="right">
            {getLangMenu()}

            {status === LoggedStatus.NON_LOGGED && (
              <Button variant="ghost-gray" onClick={() => login()}>
                {t('sign_in_button')}
              </Button>
            )}
            {status === LoggedStatus.LOGGED &&
              AuthorizationModel.isActiveSubscription(user) && (
                <Link className="games_link" as={ReactLink} to={ROUTES.GAMES}>
                  {t(`items.${ROUTES.GAMES}`)}
                </Link>
              )}
            {status === LoggedStatus.LOGGED &&
              AuthorizationModel.isActiveSubscription(user) &&
              getSubscriptionMenuItem()}
            {status === LoggedStatus.LOGGED && user && getUserMenuItem(user)}
          </HStack>
        </Box>
      </Flex>
    </Center>
  );
};

export default Menu;
