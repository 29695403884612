import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { Page } from '../../components/page/Page';
import { getAllGxGames, getGame } from '../../models/GameTypes';
import { useGetUserServersQuery } from '../../api/userServersApi';
import { GameListElement } from '../../components/game_list/GameListElement';
import { cleanServer, ServerStatusType } from '../../models/ServerType';
import './UserGamesPage.scss';
import { useAuthContext } from '../../context/AuthorizationContext';
import { useGetUserQuery } from '../../api/userApi';

export const UserGamesPage = () => {
  const { t } = useTranslation('user_game_page');
  const { user } = useAuthContext();
  const availableGames = user?.subscription?.game_types;

  const { data: userServers, refetch, isLoading } = useGetUserServersQuery();

  useEffect(() => {
    const ti = setInterval(() => {
      refetch();
    }, 5000);

    return () => {
      clearInterval(ti);
    };
  }, [refetch]);

  const { refetch: refetchUser } = useGetUserQuery();

  const canActivateNewServer =
    user?.subscription && user.subscription.active_servers_limit > 0;

  let notSubscribedServers = [
    ...getAllGxGames().filter(
      (server) =>
        !availableGames || !availableGames.find((game) => game.id === server.id),
    ),
  ];

  if (userServers) {
    notSubscribedServers = notSubscribedServers.filter(
      (game) => !userServers.find((active) => game.id === active.info.game_id),
    );

    notSubscribedServers = notSubscribedServers.sort((g1, g2) =>
      g1.name.localeCompare(g2.name),
    );
  }

  let availableGamesFiltered: string[] = [];

  if (availableGames) {
    availableGamesFiltered = availableGames
      .filter(
        (game) =>
          !userServers ||
          !userServers.find((active) => game.id === active.info.game_id.toString()),
      )
      .map((game) => game.id);

    availableGamesFiltered = availableGamesFiltered.sort((g1Id, g2Id) => {
      const g1 = getGame(g1Id);
      const g2 = getGame(g2Id);

      if (g1 && g2) {
        return g1?.name.localeCompare(g2?.name);
      }

      return 0;
    });
  }

  return (
    <Page isLoading={isLoading} translations="user_game_page" className="game_page">
      <SimpleGrid columns={1} spacing={10} className="game_list" justifyItems="center">
        <Box>
          <h4>{t('page.title')}</h4>
        </Box>
        {userServers &&
          userServers.map((server) => (
            <GameListElement
              key={server.id}
              userServer={server}
              canActivateNewServer={canActivateNewServer}
              refetch={() => refetchUser()}
            />
          ))}
        {availableGamesFiltered &&
          availableGamesFiltered.map((gameId) => (
            <GameListElement
              key={gameId}
              userServer={cleanServer(gameId, ServerStatusType.AVAILABLE)}
              canActivateNewServer={canActivateNewServer}
            />
          ))}
        {notSubscribedServers.map((game) => (
          <GameListElement
            key={game.id}
            userServer={cleanServer(game.id, ServerStatusType.UNAVAILABLE)}
          />
        ))}
      </SimpleGrid>
    </Page>
  );
};

export default UserGamesPage;
