import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IUser } from '../models/User';
import { getCSRFToken } from '../utils/token';

export const userApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
    credentials: 'include', // Tried here
    prepareHeaders: (headers, api) => {
      if (api.type === 'mutation') {
        headers.set('X-CSRFToken', <string>getCSRFToken());
      }

      return headers;
    },
  }),
  tagTypes: ['IUser'],
  endpoints: (build) => ({
    getUser: build.query<IUser, void>({
      query: () => '/users/me/',
      providesTags: [{ type: 'IUser' }],
    }),
  }),
});

export const { useGetUserQuery } = userApi;
