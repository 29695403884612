import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Input,
  Link,
  Spinner,
  Stack,
  StackDirection,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import { getGame } from '../../models/GameTypes';
import { ToastType, useToast } from '../../hooks/toast';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import {
  isGameCanStart,
  isGameServerAvailable,
  isGameServerRunning,
  isGameServerUnavailable,
  IUserServer,
  ServerStatusType,
} from '../../models/ServerType';
import { GameMetricBox } from './GameMetricBox';
import {
  getManageLink,
  useDestroyServerMutation,
  useStartServerMutation,
  useStopServerMutation,
} from '../../api/userServersApi';
import { ROUTES } from '../../utils/routes';
import './GameListElement.scss';
import { Analytics, GAEvent, GAEventType } from '../../utils/Analytics';

interface IProps {
  userServer: IUserServer;
  canActivateNewServer?: boolean;
  refetch?: () => void;
}

export const GameListElement = ({
  userServer,
  canActivateNewServer = false,
  refetch,
}: IProps) => {
  const toast = useToast();
  const { t } = useTranslation('game_list_element');
  const buttonFlexDirection = useBreakpointValue({
    base: 'column',
    xs: 'row',
    sm: 'column',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    isOpen: isStopOpen,
    onClose: onStopClose,
    onOpen: onStopOpen,
  } = useDisclosure();
  const {
    isOpen: isStartOpen,
    onClose: onStartClose,
    onOpen: onStartOpen,
  } = useDisclosure();

  const {
    isOpen: isDestroyOpen,
    onClose: onDestroyClose,
    onOpen: onDestroyOpen,
  } = useDisclosure();
  const [stopServer, { isLoading: isDeleting }] = useStopServerMutation();

  const [startServer, { isLoading: isStarting }] = useStartServerMutation();

  const [destroyServer, { isLoading: isDestroying }] = useDestroyServerMutation();
  const { info } = userServer;
  const game = getGame(userServer.info.game_id);

  const getGameStatus = ({ info: gameInfo }: IUserServer, showSpinner: boolean) => {
    const { t: tr } = useTranslation('game_status');
    const text = tr(`statuses.${gameInfo.status}`);

    if (
      isGameServerUnavailable(gameInfo.status) ||
      isGameServerAvailable(gameInfo.status)
    ) {
      return <></>;
    }

    if (showSpinner) {
      return <Spinner />;
    }

    return <div className={`status ${gameInfo.status}`}>{text}</div>;
  };

  const getPanelCssClass = (gameStatus: ServerStatusType) => {
    switch (gameStatus) {
      case ServerStatusType.AVAILABLE:
        return 'available';
      case ServerStatusType.UNAVAILABLE:
        return 'unavailable';
      default:
    }

    return 'active';
  };

  const getButtons = (lgame: IUserServer) => (
    <>
      {getGameStatus(lgame, isDeleting || isDestroying || isStarting)}

      {!isGameServerUnavailable(lgame.info.status) &&
        !isGameServerAvailable(lgame.info.status) && (
          <>
            <Link
              onClick={() => {
                Analytics.getInstance().sendEvent(GAEvent(GAEventType.serverManage));
              }}
              href={getManageLink(lgame.id)}
              target="_blank"
              className="nohover">
              <Button size="sm">{t('manage_button')}</Button>
            </Link>
            <ConfirmDialog
              title={t('manage.stop_dialog_title')}
              isOpen={isStopOpen}
              onClose={onStopClose}
              onAccept={() => {
                Analytics.getInstance().sendEvent(GAEvent(GAEventType.serverStop));
                stopServer(lgame.id);
              }}
            />
            <ConfirmDialog
              title={t('manage.start_dialog_title')}
              isOpen={isStartOpen}
              onClose={onStartClose}
              onAccept={() => {
                startServer(lgame.id);
                Analytics.getInstance().sendEvent(GAEvent(GAEventType.serverStart));
              }}
            />
            {isGameCanStart(lgame.info.status) && (
              <Button name="start" size="sm" variant="ghost" onClick={onStartOpen}>
                {t('start_button')}
              </Button>
            )}
            {lgame.info.status === ServerStatusType.RUNNING && (
              <Button name="stop" size="sm" variant="ghost" onClick={onStopOpen}>
                {t('stop_button')}
              </Button>
            )}
            <ConfirmDialog
              title={t('manage.destroy_dialog_title')}
              message={t('manage.destroy_dialog_message')}
              isOpen={isDestroyOpen}
              onClose={onDestroyClose}
              onAccept={async () => {
                await destroyServer(lgame.id);
                Analytics.getInstance().sendEvent(GAEvent(GAEventType.serverDelete));

                if (refetch) {
                  refetch();
                }
              }}
            />
            <Button name="destroy" size="sm" variant="ghost" onClick={onDestroyOpen}>
              {t('destroy_button')}
            </Button>
          </>
        )}
      {isGameServerAvailable(lgame.info.status) && canActivateNewServer && (
        <>
          <Link
            as={ReactLink}
            to={`${ROUTES.CREATE_SERVER}/${lgame.info.game_id}`}
            className="nohover">
            <Button size="sm">{t('create_server_button')}</Button>
          </Link>
        </>
      )}
    </>
  );
  return (
    <div className={`game_list_element_outer ${getPanelCssClass(info.status)}`}>
      <Flex
        className="game_list_element "
        alignItems="center"
        flexDirection={{
          'base': 'column',
          'sm': 'row',
          'md': 'row',
          'lg': 'row',
          'xl': 'row',
          '2xl': 'row',
        }}>
        <Box
          className="game_img"
          backgroundImage={`/assets/images/games/${info.game_id}-2.png`}
        />
        {isGameServerUnavailable(info.status) ? (
          <Flex
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
            className="game_unavailable">
            <VStack>
              <Image src="/assets/images/locker.svg" />
              <Container>
                {t('upgrade')} {game?.name.toUpperCase()}
              </Container>
            </VStack>
          </Flex>
        ) : (
          <Flex
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
            className="game_metrics">
            {isGameServerRunning(info.status) && (
              <>
                <GameMetricBox
                  text={`${Math.round(info.current_cpu)}%`}
                  label={t('cpu_box')}
                  fill={Math.max(info.current_cpu, 18) / 100}
                />
                <GameMetricBox
                  text={`${info.current_players}/${info.max_players}`}
                  label={t('players_box')}
                  fill={info.current_players / info.max_players}
                />
              </>
            )}
            {isGameServerAvailable(info.status) && (
              <>
                <GameMetricBox text="0%" label={t('cpu_box')} fill={0} />
                <GameMetricBox text="0" label={t('players_box')} fill={0} />
              </>
            )}
          </Flex>
        )}
        {!isGameServerUnavailable(userServer.info.status) && (
          <>
            <Box className="server_info_box" flexGrow={1}>
              <h6>{game?.name}</h6>
              <p>
                <span>{t('connection_info')}</span>
                {info.connection_info && (
                  <span>
                    {info.connection_info}
                    <CopyToClipboard
                      text={info.connection_info}
                      onCopy={() => toast(t('copied_clipboard'), ToastType.info)}>
                      <Image w="16px" h="16px" src="/assets/images/copy.svg" />
                    </CopyToClipboard>
                  </span>
                )}
              </p>
              <hr />
              <p>
                <span>{t('rcon_info')}</span>
                {info.rcon_info && <span>{info.rcon_info}</span>}
              </p>
              <p>
                <span>{t('rcon_password')}</span>
                {info.rcon_password && (
                  <span>
                    <Input
                      readOnly
                      type={showPassword ? 'text' : 'password'}
                      value={info.rcon_password}
                    />
                    <Image
                      w="24px"
                      h="24px"
                      src="/assets/images/eye.svg"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                    <CopyToClipboard
                      text={info.rcon_password}
                      onCopy={() => toast(t('copied_clipboard'), ToastType.info)}>
                      <Image w="16px" h="16px" src="/assets/images/copy.svg" />
                    </CopyToClipboard>
                  </span>
                )}
              </p>
            </Box>
            <Box className="server_buttons">
              <Stack
                direction={buttonFlexDirection as StackDirection}
                spacing={4}
                align="stretch">
                {getButtons(userServer)}
              </Stack>
            </Box>
          </>
        )}
      </Flex>
    </div>
  );
};

export default GameListElement;
