import { clearCSRFToken, getCSRFToken } from '../utils/token';
import { IUser } from './User';
import { Analytics, GAEvent, GAEventType } from '../utils/Analytics';

const AUTH_URL = '/api/accounts';
const loginUrl: URL = new URL(
  `${process.env.REACT_APP_BACKEND_URL}${AUTH_URL}/opera/login`,
);

if (process.env.REACT_APP_REDIRECT_TO) {
  loginUrl.searchParams.append('redirect-to', process.env.REACT_APP_REDIRECT_TO);
}

export const redirectToLogin = () => {
  window.location.href = loginUrl.href;
};

export const AuthorizationModel = {
  login: () => {
    Analytics.getInstance().sendEvent(GAEvent(GAEventType.signin));
    redirectToLogin();
  },
  logout: async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}${AUTH_URL}/logout/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRFToken': getCSRFToken() || '',
        },
      });

      Analytics.getInstance().sendEvent(GAEvent(GAEventType.logout));
    } catch (e) {}

    clearCSRFToken();
    window.location.href = '/';
  },
  isActiveSubscription: (user: IUser | undefined) =>
    user && user.subscription && user.subscription.active_servers_limit !== -1,
};

export default loginUrl.href;
