import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { ROUTES } from '../utils/routes';
import { OAuthRedirect } from '../pages/oauth/OAuthRedirect';
import { Error404Page } from '../pages/errors/Error404Page';
import { NewHomePage } from '../pages/home-2/NewHomePage';

export const nonAuthenticatedLayout = () => (
  <Routes>
    <Route path="/oauth2/callback" element={<OAuthRedirect />} />
    <Route path={ROUTES.HOME} element={<NewHomePage />} />
    <Route path={ROUTES.HOW_IT_WORKS} element={<NewHomePage />} />
    <Route path={ROUTES.SUBSCRIPTION} element={<NewHomePage />} />
    <Route path="/" element={<NewHomePage />} />
    <Route path="*" element={<Error404Page />} />
  </Routes>
);

export default nonAuthenticatedLayout;
