import { defineStyleConfig } from '@chakra-ui/react';

export const Modal = defineStyleConfig({
  baseStyle: {
    dialog: {
      minWidth: '600px',
      outline: 'none',
      marginTop: '10px',
      borderColor: 'brand.ghost-light',
      borderWidth: '1px',
      borderRadius: '10px 10px 10px 10px',
      p: '20px',
      textAlign: 'center',
      color: 'white',
      bg:
        'radial-gradient(circle at 50% -60%, rgba(var(--chakra-colors-brand-ghost-light-n), 0.45) 20%, ' +
        'rgba(0, 0, 0, 0) 60%), rgba(var(--chakra-colors-brand-body-n), 0.90)',
      backdropFilter: 'blur(4px)',
      filter:
        'drop-shadow(0px 0px 60px rgba(var(--chakra-colors-brand-ghost-light-n), 0.7))',
      header: {
        fontSize: '24px',
      },
      body: {
        textTransform: 'none',
        color: 'var(--chakra-colors-brand-ghost-light)',
        fontSize: '17px',
      },
      footer: {
        justifyContent: 'center',
      },
      button: {
        margin: '10px',
        letterSpacing: '0px',
        textTransform: 'none',
      },
      a: {
        margin: '10px',
      },
    },
  },
  variants: {},
});

export default Modal;
