import { ANALYTICS_EVENT, getItem, removeItem } from './LocalStorage';

type EventTypes = GAEventType;

export interface IAnalyticsEvent {
  category: string;
  label?: EventTypes;
  value?: number;
  type: AnaliticInvokeType;
}

export interface IAnalyticsFacade<T extends IAnalyticsEvent> {
  sendEvent(event: T, value: number): void;
}

type AnaliticInvokeType = 'ga' | 'custom';

export enum GAEventType {
  serverStop = 'server_stop',
  serverStart = 'server_stop',
  serverDelete = 'server_stop',
  serverManage = 'server_manage',
  serverCreate = 'server_stop',
  subscriptionChoose = 'subscription_choose',
  subscriptionManage = 'subscription_manage',
  helpClick = 'help_click',
  profileClick = 'profile_click',
  subscriptionUpgrade = 'subscription_upgrade',
  subscriptionMenuClick = 'subscription_menu_click',
  userMenuClick = 'user_menu_click',
  langMenuClick = 'lang_menu_click',
  changeLang = 'change_lang',
  burgerMenuClick = 'burger_menu_click',
  logoClick = 'logo_click',
  signin = 'signin',
  logout = 'logout',
  carousel1LeftClick = 'carousel_1_left_click',
  carousel1RightClick = 'carousel_1_left_right',
  carousel2LeftClick = 'carousel_2_left_click',
  carousel2RightClick = 'carousel_2_left_right',
}

export const GAEvent = (
  event: GAEventType,
): IAnalyticsEvent & {
  label: GAEventType;
} => ({
  category: 'frontend',
  label: event,
  type: 'ga',
});

export class Analytics implements IAnalyticsFacade<IAnalyticsEvent> {
  static instance: Analytics | null = null;

  public static getInstance = (): Analytics => {
    if (Analytics.instance === null) {
      Analytics.instance = new Analytics();
    }

    return Analytics.instance;
  };

  // eslint-disable-next-line class-methods-use-this
  public sendEvent(event: IAnalyticsEvent): void {
    if (event.type === 'ga' && window.gtag) {
      window.gtag('event', event.label);
    }
  }

  public handleStoredGAEvent(): void {
    const ev = getItem(ANALYTICS_EVENT);

    if (ev) {
      removeItem(ANALYTICS_EVENT);
      const eventType: any = GAEventType[ev as keyof typeof GAEventType];

      if (eventType) {
        this.sendEvent(GAEvent(eventType));
      }
    }
  }
}
