import { Route, Routes } from 'react-router-dom';
import { CreateServerPage } from '../pages/create_server/CreateServerPage';
import { UserGamesPage } from '../pages/user_games/UserGamesPage';
import { ROUTES } from '../utils/routes';
import { OAuthRedirect } from '../pages/oauth/OAuthRedirect';
import { useAuthContext } from '../context/AuthorizationContext';
import { AuthorizationModel } from '../models/AuthorizationModel';
import { NewHomePage } from '../pages/home-2/NewHomePage';

const getActiveSubscriptionRoutes = () => {
  const { user } = useAuthContext();

  if (AuthorizationModel.isActiveSubscription(user)) {
    return (
      <>
        <Route path={`${ROUTES.CREATE_SERVER}/:id`} element={<CreateServerPage />} />
        <Route path={ROUTES.GAMES} element={<UserGamesPage />} />
      </>
    );
  }

  return <></>;
};

export const AuthenticatedLayout = () => (
  <Routes>
    <Route path={ROUTES.OAUTH} element={<OAuthRedirect />} />
    {getActiveSubscriptionRoutes()}
    <Route path="*" element={<NewHomePage />} />
  </Routes>
);
export default AuthenticatedLayout;
