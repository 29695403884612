import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { setCSRFToken } from '../../utils/token';
import { Page } from '../../components/page/Page';
import { ROUTES } from '../../utils/routes';
import { useAuthContext } from '../../context/AuthorizationContext';
import { NO_TOKEN_VALUE } from '../../utils/LocalStorage';

export const OAuthRedirect = () => {
  const { t } = useTranslation('oauth_page');
  const { user } = useAuthContext();
  const params = new URLSearchParams(window.location.search);
  const token = params.get('csrftoken');
  const returnUrl = params.get('return-url');

  if (token) {
    if (!setCSRFToken(token)) {
      console.error('Problem with setting token');
    }
  } else {
    setCSRFToken(NO_TOKEN_VALUE);
  }

  setTimeout(() => {
    if (returnUrl && process.env.REACT_APP_AUTH_URL) {
      if (returnUrl.startsWith(`${process.env.REACT_APP_AUTH_URL}/`)) {
        window.location.href = returnUrl;
        return;
      }
    }

    if (user && user?.subscription) {
      window.location.href = ROUTES.GAMES;
    } else {
      window.location.href = ROUTES.HOME;
    }
  }, 100); // need to wait for localstorage

  return (
    <Page translations="oauth_page">
      <Flex
        height="120vh"
        alignItems="center"
        flexDirection="column"
        alignContent="center"
        justifyContent="flex-start">
        <Spinner size="xl" marginTop="20vh" marginBottom="3vh" />
        <h3>{t('page.wait')}</h3>
      </Flex>
    </Page>
  );
};

export default OAuthRedirect;
