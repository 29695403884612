import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import './GameMetricBox.scss';

interface IProps {
  text: string;
  label: string;
  fill: number;
}

export const GameMetricBox = ({ text, label, fill }: IProps) => {
  const percent = fill * 265 - 235;
  const [rand] = useState<number>(Math.random() * 3); // we need the same seed for rand even if user click on show password for
  return (
    <Box className={`cpu_info_box ${percent > 4 ? 'animated' : ''}`}>
      {label}
      <Box
        style={{
          backgroundPositionY: `${percent}%`,
          animationDuration: `${Math.round(rand) + 5}s`,
        }}>
        <span>{text}</span>
      </Box>
    </Box>
  );
};

export default GameMetricBox;
