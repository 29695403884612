import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-cycle
import { setLang } from './lang';
import en from './translations/en.json';
import pl from './translations/pl.json';
import de from './translations/de.json';

export const DEFAULT_LANG = 'en';
export const SUPPORTED_LANGS = ['en', 'pl', 'de'];

i18n.on('initialized', () => {
  setLang(i18n.language, false);
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      pl,
      de,
    },
    fallbackLng: DEFAULT_LANG,
    supportedLngs: SUPPORTED_LANGS,
    debug: false,
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupFromPathIndex: process.env.REACT_APP_BUILD_TARGET === 'EXT' ? 1 : 0,
    },
  });

export default i18n;
