import { IGame } from './ServerType';

export interface IUser {
  username: string;
  email: string;
  fullname: string;
  profile_image_url: string;
  profile_link: string;
  subscription?: IActiveSubscription;
}

export interface IActiveSubscription {
  servers: string[];
  details: { [serverName: string]: string };
  game_types: IGame[];
  upgrade_link?: string; // only if user can upgrade to higher plan
  manage_link: string;
  active_servers_limit: number;
}

export enum LoggedStatus {
  UNKNOWN,
  LOGGED,
  NON_LOGGED,
}
