import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import './HomeGameCarousel.scss';
import { useTranslation } from 'react-i18next';
import GameTypes from '../../models/GameTypes';
import { Analytics, GAEvent, GAEventType } from '../../utils/Analytics';

interface ICarouselGame {
  image: string;
  name: string;
  logo: string;
}

export const HomeGameCarousel = () => {
  const { t } = useTranslation('home_page_2');
  const [gameIndex, setGameIndex] = useState<number>(-1);
  const [games, setGames] = useState<ICarouselGame[]>([]);

  const rotateArray = (arr: ICarouselGame[], index: number) => {
    // Check if the array is empty or the index is 0
    if (arr.length === 0 || index === 0) {
      return arr;
    }

    // Calculate the effective index based on array length and given index
    const effectiveIndex = index % arr.length;

    // Slice the array based on the effective index and concatenate the two parts
    if (effectiveIndex >= 0) {
      return arr.slice(effectiveIndex).concat(arr.slice(0, effectiveIndex));
    }

    return arr.slice(effectiveIndex).concat(arr.slice(0, arr.length + effectiveIndex));
  };

  useEffect(() => {
    setGames(
      rotateArray(
        GameTypes.map((game) => ({
          image: `/assets/images/games/carousel/${game.id}.png`,
          name: game.name,
          logo: `/assets/images/games/carousel/${game.id}-logo.png`,
        })),
        gameIndex,
      ),
    );
  }, [gameIndex, setGames]);

  return (
    <div className="home-game-carousel">
      <div className="carousel">
        <span
          className="left"
          onClick={() => {
            setGameIndex(gameIndex - 1);
            Analytics.getInstance().sendEvent(GAEvent(GAEventType.carousel1LeftClick));
          }}>
          <Image src="/assets/images/arrow-left.svg" />
        </span>
        {games.length > 0 && (
          <>
            <div className="game-title">
              <h3>{t('page.section2.header')}</h3>
              <div>
                <h4>
                  {games[0].name}
                  <Image src={games[0].logo} />
                </h4>
              </div>
            </div>
            <div className="game primary">
              <Image src={games[0].image} />
              <div className="fill" />
            </div>

            <div className="game secondary">
              <Image src={games[1].image} />
            </div>
            <div className="game secondary">
              <Image src={games[2].image} />
            </div>
            <div className="game secondary">
              <Image src={games[3].image} />
            </div>
          </>
        )}
        <span
          className="right"
          onClick={() => {
            setGameIndex(gameIndex + 1);
            Analytics.getInstance().sendEvent(GAEvent(GAEventType.carousel1RightClick));
          }}>
          <Image src="/assets/images/arrow-right.svg" />
        </span>
      </div>
    </div>
  );
};

export default HomeGameCarousel;
