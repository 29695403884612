import { Center, Spinner, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const Loader = () => {
  const { t } = useTranslation('loader');
  return (
    <Center>
      <VStack>
        <Spinner size="xl" />
        <h5>{t('loading')}</h5>
      </VStack>
    </Center>
  );
};

export default Loader;
