// eslint-disable-next-line import/no-cycle
import i18n from 'i18next';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_LANG, SUPPORTED_LANGS } from './i18n';
import { Analytics, GAEvent, GAEventType } from '../utils/Analytics';

const LANG_KEY = 'lang';

export const validateLang = (lang: string | null) =>
  !!lang && SUPPORTED_LANGS.indexOf(lang) !== -1;

export function setLang(lang: string, sendAnalytics = true) {
  if (sendAnalytics) {
    Analytics.getInstance().sendEvent(GAEvent(GAEventType.changeLang));
  }

  if (validateLang(lang)) {
    window.localStorage.setItem(LANG_KEY, lang);
    i18n.changeLanguage(lang);
  }
}

export function getLang(): string {
  const storageLang = window.localStorage.getItem(LANG_KEY);

  if (validateLang(storageLang)) {
    return storageLang || DEFAULT_LANG;
  }

  return DEFAULT_LANG;
}
