import { defineStyleConfig } from '@chakra-ui/react';

export const Switch = defineStyleConfig({
  baseStyle: {
    thumb: {
      background: 'gray.300',
      _checked: {
        background: 'gray.100',
      },
    },
    track: {
      background: 'rgba(0,0,0,0)',
      border: '1px solid',
      borderColor: 'gray.600',
      color: 'red',
      _checked: {
        borderColor: 'brand.primary',
        color: 'brand.primary',
        background: 'rgba(var(--chakra-colors-brand-primary-n), 0.2)',
        borderWidth: '1px',
      },
    },
  },
});

export default Switch;
