import React, { useMemo } from 'react';
import { IUser, LoggedStatus } from '../models/User';
import { AuthorizationModel } from '../models/AuthorizationModel';
import { useGetUserQuery } from '../api/userApi';

const USER_GET_REFRESH_INTERVAL = 30000; // 30s

const login = () => {
  AuthorizationModel.login();
};

const logout = async () => {
  AuthorizationModel.logout();
};

export interface IAuthorizationContext {
  user: IUser | undefined;
  status: LoggedStatus;
  login: () => void;
  logout: () => void;
}

const defaultValue: IAuthorizationContext = {
  user: undefined,
  status: LoggedStatus.UNKNOWN,
  login,
  logout,
};
export const AuthorizationContext: React.Context<IAuthorizationContext> =
  React.createContext(defaultValue);

export const AuthProvider = (props: any) => {
  const { children } = props;
  const { data, error } = useGetUserQuery(undefined, {
    pollingInterval: USER_GET_REFRESH_INTERVAL,
  });

  const authCtxOptions = useMemo(
    () => ({
      user: data,
      status: data && !error ? LoggedStatus.LOGGED : LoggedStatus.NON_LOGGED,
      login,
      logout,
    }),
    [data, error],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthorizationContext.Provider value={authCtxOptions}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = React.useContext(AuthorizationContext);

  if (context === undefined) {
    throw new Error('useAuthContext have to be AuthorizationContext');
  }

  return context;
};
