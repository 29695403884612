import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({
  title,
  message,
  isOpen,
  onClose,
  onAccept,
}: {
  title: string;
  message?: string;
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}) => {
  const cancelRef = useRef(null);
  const { t } = useTranslation('confirmation_dialog');

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>

          {message && <AlertDialogBody>{message}</AlertDialogBody>}

          <AlertDialogFooter>
            <Button
              onClick={() => {
                onAccept();
                onClose();
              }}
              ml={3}>
              {t('yes')}
            </Button>
            <Button variant="ghost" ref={cancelRef} onClick={onClose}>
              {t('cancel')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmDialog;
