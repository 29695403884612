import React, { useEffect } from 'react';
import { ChakraProvider, Container, createStandaloneToast } from '@chakra-ui/react';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { useLocation } from 'react-router-dom';
import NonAuthenticatedLayout from './layouts/NonAuthenticatedLayout';
import { ColorNames, defaultColors, getTheme } from './theme/theme';
import { LoggedStatus } from './models/User';
import { useAuthContext } from './context/AuthorizationContext';
import { AuthenticatedLayout } from './layouts/AuthenticatedLayout';
import { Menu } from './components/menu/Menu';
import { Footer } from './components/footer/Footer';
import CookieConsent from './components/cookie-consent/CookieConsent';
import './i18n/i18n';

export const { ToastContainer, toast } = createStandaloneToast();

const App = () => {
  const { pathname } = useLocation();
  const { status } = useAuthContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const colors = {
    [ColorNames.accent]: defaultColors[ColorNames.accent],
    [ColorNames.secondaryAccent]: defaultColors[ColorNames.secondaryAccent],
    [ColorNames.ghost]: defaultColors[ColorNames.ghost],
    [ColorNames.ghostLight]: defaultColors[ColorNames.ghostLight],
  };

  const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  const cssFilterConfig = {
    acceptanceLossPercentage: 1,
    maxChecks: 5,
    forceFilterRecalculation: true,
  };

  const rgbToHex = (r: number, g: number, b: number) =>
    `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  // eslint-disable-next-line max-len
  const cssFilter = hexToCSSFilter(
    rgbToHex(
      defaultColors[ColorNames.ghost].r,
      defaultColors[ColorNames.ghost].g,
      defaultColors[ColorNames.ghost].b,
    ),
    cssFilterConfig,
  );
  // eslint-disable-next-line max-len
  const secondaryCssFilter = hexToCSSFilter(
    rgbToHex(
      defaultColors[ColorNames.accent].r,
      defaultColors[ColorNames.accent].g,
      defaultColors[ColorNames.accent].b,
    ),
    cssFilterConfig,
  );

  return (
    <ChakraProvider theme={getTheme(colors, cssFilter.filter, secondaryCssFilter.filter)}>
      <Container minWidth="100%" padding={0}>
        <Menu />
        {status === LoggedStatus.LOGGED ? (
          <AuthenticatedLayout />
        ) : (
          <NonAuthenticatedLayout />
        )}
        <Footer />
      </Container>
      <CookieConsent />
    </ChakraProvider>
  );
};

export default App;
