import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Center, Spinner } from '@chakra-ui/react';
import './Page.scss';

interface IProps {
  translations: string;
  className?: string;
  children: React.ReactNode;
  isLoading?: boolean;
}

export const Page = ({
  children,
  className = '',
  translations,
  isLoading = false,
}: IProps) => {
  const { t } = useTranslation(translations);
  return (
    <div className={`page ${className}`}>
      <Helmet>
        <title>{t('head.title')}</title>
        <meta name="description" content={t('head.description')} />
      </Helmet>

      <Center>{isLoading ? <Spinner /> : children}</Center>
    </div>
  );
};

export default Page;
