import { CSRF_TOKEN_KEY, getItem, removeItem, setItem } from './LocalStorage';

export function setCSRFToken(token: string) {
  return setItem(CSRF_TOKEN_KEY, token);
}

export function getCSRFToken(): string | null {
  return getItem(CSRF_TOKEN_KEY);
}

export function clearCSRFToken() {
  removeItem(CSRF_TOKEN_KEY);
}
