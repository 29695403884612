import { SUPPORTED_LANGS } from '../i18n/i18n';

export enum ROUTES {
  HOME = '/home',
  HOW_IT_WORKS = '/how_it_works',
  SUBSCRIPTION = '/subscription',
  GAMES = '/games',
  CREATE_SERVER = '/games/new-server',
  OAUTH = '/oauth2/callback',
}

export const baseLink = (link: string) =>
  `/:language(${SUPPORTED_LANGS.join('|')})?${link}`;

export default ROUTES;
