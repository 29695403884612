import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { StatusCodes } from 'http-status-codes';
import { serversApi } from './api/servers';
import { userApi } from './api/userApi';
import { toast } from './App';
import { ToastType } from './hooks/toast';
import { userServersApi } from './api/userServersApi';

const rootReducer = combineReducers({
  [userServersApi.reducerPath]: userServersApi.reducer,
  [serversApi.reducerPath]: serversApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.data && action.payload.data.detail) {
      // for user/me we don;t show errors,because we are checking if user is logged
      if (!action.meta || action.meta.arg?.endpointName !== 'getUser') {
        toast({
          title: action.payload.data.detail,
          status: ToastType.error.status,
          duration: 8000,
          isClosable: true,
          position: 'top',
          variant: ToastType.error.variant,
        });
      }
    } else if (action.payload.originalStatus === StatusCodes.BAD_REQUEST) {
      toast({
        title: action.payload.data,
        status: ToastType.error.status,
        duration: 8000,
        isClosable: true,
        position: 'top',
        variant: ToastType.error.variant,
      });
    } else if (action.payload.error) {
      toast({
        title: action.payload.error,
        status: ToastType.error.status,
        duration: 8000,
        isClosable: true,
        position: 'top',
        variant: ToastType.error.variant,
      });
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rtkQueryErrorLogger,
      userServersApi.middleware,
      serversApi.middleware,
      userApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
