import { extendTheme } from '@chakra-ui/react';
import { Card } from './card-theme';
import { Button } from './button-theme';
import { Radio } from './radio-theme';
import { Switch } from './switch-theme';
import { Popover } from './popover-theme';
import { Modal } from './modal-theme';
import { Menu } from './menu-theme';
import { IGxColor } from '../types/types';

export enum ColorNames {
  accent = 'accent',
  secondaryAccent = 'secondaryAccent',
  ghost = 'ghost',
  ghostLight = 'ghostLight',
}

export const defaultColors = {
  [ColorNames.accent]: {
    alpha: 2,
    r: 250,
    g: 30,
    b: 78,
  },
  [ColorNames.secondaryAccent]: {
    alpha: 2,
    r: 165,
    g: 3,
    b: 38,
  },
  [ColorNames.ghost]: {
    alpha: 2,
    r: 46,
    g: 39,
    b: 63,
  },
  [ColorNames.ghostLight]: {
    alpha: 2,
    r: 92,
    g: 78,
    b: 126,
  },
};

export interface Colors {
  [ColorNames.accent]: IGxColor;
  [ColorNames.secondaryAccent]: IGxColor;
  [ColorNames.ghost]: IGxColor;
  [ColorNames.ghostLight]: IGxColor;
}

const getThemeColors = (
  colors: Colors,
  backgroundFilter: string,
  secondaryBackgroundFilter: string,
) => {
  const c = {
    brand: {
      'body-n': '28, 22, 52',
      // 'body-n':        '28, 23, 38',
      'button-bg': '25, 22, 52',
      'button-hover-bg': '87, 96, 134',
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
      'gray-border': '#fff',
      'gray-bg-n': '40, 40, 40',
      'filter': backgroundFilter,
      'secondary-filter': secondaryBackgroundFilter,
      // eslint-disable-next-line max-len
      'primary': `rgba(${colors[ColorNames.accent].r},${colors[ColorNames.accent].g}, ${
        colors[ColorNames.accent].b
      }, ${colors[ColorNames.accent].alpha})`,
      'primary-n': `${colors[ColorNames.accent].r},${colors[ColorNames.accent].g}, ${
        colors[ColorNames.accent].b
      }`,
      // eslint-disable-next-line max-len
      'secondary': `rgba(${colors[ColorNames.secondaryAccent].r},${
        colors[ColorNames.secondaryAccent].g
      }, ${colors[ColorNames.secondaryAccent].b}, ${
        colors[ColorNames.secondaryAccent].alpha
      })`,
      'third': '#BFB6D2',
      // eslint-disable-next-line max-len
      'ghost': `rgba(${colors[ColorNames.ghost].r},${colors[ColorNames.ghost].g}, ${
        colors[ColorNames.ghost].b
      }, ${colors[ColorNames.ghost].alpha})`,
      'ghost-n': `${colors[ColorNames.ghost].r}, ${colors[ColorNames.ghost].g}, ${
        colors[ColorNames.ghost].b
      }`,
      // eslint-disable-next-line max-len
      'ghost-light': `rgba(${colors[ColorNames.ghostLight].r},${
        colors[ColorNames.ghostLight].g
      }, ${colors[ColorNames.ghostLight].b}, ${colors[ColorNames.ghostLight].alpha})`,
      'ghost-light-n': `${colors[ColorNames.ghostLight].r}, ${
        colors[ColorNames.ghostLight].g
      }, ${colors[ColorNames.ghostLight].b}`,
      'ghost-lighter': '#5C4E7E',
    },
  };
  return c;
};

const createTheme = (colors: any) =>
  extendTheme({
    colors,

    breakpoints: {
      'xs': '800px',
      'sm': '1024px',
      'md': '1280px',
      'lg': '1366px',
      'xl': '1920px',
      '2xl': '3800px',
    },
    config: {
      useSystemColorMode: false,
      initialColorMode: 'dark',
    },
    styles: {
      global: {
        body: {
          'bg': 'rgba(0,0,0,0)',
          'color': '#fff',
          '--font-color-main': 'red',
          'fontFamily': 'Chakra Petch',
        },
      },
    },
    components: {
      Card,
      Button,
      Radio,
      Popover,
      Switch,
      Modal,
      Menu,
      Alert: {
        variants: {
          'gx-error': {
            container: {
              color: 'gray.50',
              borderColor: 'brand.primary',
              borderWidth: '1px',
              bg:
                'radial-gradient(circle at 50% -60%, rgba(var(--chakra-colors-brand-primary-n), 0.3) 20%, rgba(0, 0, 0, 0) 60%), ' +
                'rgba(var(--chakra-colors-brand-primary-n), 0.75)',
            },
          },
          'gx-info': {
            container: {
              color: 'gray.50',
              borderColor: 'brand.ghost-light',
              borderWidth: '1px',
              bg:
                'radial-gradient(circle at 50% -60%, rgba(var(--chakra-colors-brand-ghost-light-n), 0.5) 20%, rgba(0, 0, 0, 0)' +
                ' 60%), ' +
                'rgba(var(--chakra-colors-brand-ghost-n), 0.95)',
            },
          },
        },
      },
    },
  });

export const getTheme = (
  colors: Colors,
  backgroundFilter: string,
  secondaryBackgroundFilter: string,
) => createTheme(getThemeColors(colors, backgroundFilter, secondaryBackgroundFilter));

export default getTheme;
