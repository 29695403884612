export const CSRF_TOKEN_KEY = 'ctoken';
export const NO_TOKEN_VALUE = 'NoToken';
export const ANALYTICS_EVENT = 'analytics_event';

export function setItem(key: string, item: string) {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem(key, item);
    return true;
  }

  return false;
}

export function getItem(key: string): string | null {
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage.getItem(key);
  }

  return null;
}

export function removeItem(key: string) {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.removeItem(key);
  }
}
