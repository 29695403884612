import { IGame } from './ServerType';

export const AVAILABLE_GAMES_IDS = ['1', '2', '3', '4', '5', '6', '7'];
export const MINECRAFT_JAVA_ID = '7';
export const MINECRAFT_BEDROCK_ID = '6';
export const Games: IGame[] = [
  {
    id: '1',
    name: 'Terraria',
  },
  {
    id: '2',
    name: 'Rust',
  },
  {
    id: '3',
    name: 'ARK: Survival Evolved',
  },
  {
    id: '4',
    name: 'Counter-Strike 2',
  },
  {
    id: '5',
    name: "Garry's Mod",
  },
  {
    id: MINECRAFT_BEDROCK_ID,
    name: 'Minecraft: Bedrock',
  },
  {
    id: MINECRAFT_JAVA_ID,
    name: 'Minecraft: Java',
  },
];
export const getAllGxGames = () =>
  Games.filter((game) => AVAILABLE_GAMES_IDS.indexOf(game.id) !== -1);

export const getGame = (id: string) =>
  getAllGxGames().find((el) => el.id.toString() === id.toString());

export default Games;
