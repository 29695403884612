import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IServerType } from '../models/ServerType';
import { getCSRFToken } from '../utils/token';

export const serversApi = createApi({
  reducerPath: 'servers',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api/servers`,
    credentials: 'include',
    prepareHeaders: (headers, api) => {
      if (api.type === 'mutation') {
        headers.set('X-CSRFToken', <string>getCSRFToken());
      }

      return headers;
    },
  }),
  tagTypes: ['ServerType'],
  endpoints: (build) => ({
    getServerTypes: build.query<IServerType[], void>({
      query: () => '/',
      providesTags: [{ type: 'ServerType' }],
    }),
  }),
});

export const { useGetServerTypesQuery } = serversApi;
