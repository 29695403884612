import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys,
);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    borderColor: 'brand.ghost',
    borderWidth: '2px',
    color: 'brand.third',
    bg: 'rgba(74, 62, 101, 0.1)',
  },
  body: {
    padding: '100px',
  },
});

const sizes = {
  md: definePartsStyle({
    container: {
      borderRadius: '0px',
    },
  }),
};

export const Card = defineMultiStyleConfig({ baseStyle, sizes });
export default Card;
