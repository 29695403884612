export enum CurrencyType {
  USD = 'usd',
  EUR = 'eur',
}

export interface ISubscriptionOption {
  id: string;
  period: number;
  name: SubscriptionType;
  price: number;
  price_display: string;
  currency: CurrencyType;
  payment_url: string;
}

export enum SubscriptionType {
  monthly = 'monthly',
  semiannually = 'semiannually',
  annually = 'annually',
}

export interface IGame {
  id: string;
  name: string;
}

export interface IServerType {
  id: string;
  name: string;
  game_types: IGame[];
  players: number;
  slug: string;
  subscription_options: ISubscriptionOption[];
}

export enum ServerStatusType {
  DISABLED = 'DISABLED',
  RUNNING = 'RUNNING',
  NOT_EXECUTED = 'NOT_EXECUTED',
  EXECUTING = 'EXECUTING',
  RETRY = 'RETRY',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  STOPPED = 'STOPPED',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface IUserServer {
  id: string;
  info: {
    game_id: string;
    status: ServerStatusType;
    connection_info: string;
    current_bandwidth: number;
    current_cpu: number; // 0-100
    current_memory: number;
    current_memory_percent: number; // 0-100
    current_players: number;
    ftp_info: string;
    rcon_info: string;
    rcon_password: string;
    max_players: number;
  };
}

export interface IGameOptions {
  game_id: string;
  game_datacenter: string;
  start_game: boolean;
  options: IGameAdvancedOptions;
}

export interface IGameAdvancedOptions {
  [name: string]:
    | {
        id: string;
        name: string;
      }[]
    | string;
}

export interface ICreateServerStatus {
  id: string;
}

export interface ICreateServerProgress {
  task_id: string;
  current_step_id: string;
  enabled: boolean;
  status: ServerStatusType;
  total_steps: number;
  steps: [
    {
      step_id: string;
      name: string;
      last_log: string;
      progress: number; // 0-100
    },
  ];
}

export const MinecraftJavaOptions: IGameAdvancedOptions = {
  java: [
    {
      id: '1',
      name: 'Java 18',
    },
    {
      id: '2',
      name: 'Java 8',
    },
  ],
};

export const cleanServer = (
  gameId: string,
  status = ServerStatusType.AVAILABLE,
): IUserServer => ({
  id: '',
  info: {
    game_id: gameId,
    status,
    connection_info: '',
    current_bandwidth: 0,
    current_cpu: 0, // 0-100
    current_memory: 0,
    current_memory_percent: 0, // 0-100
    current_players: 0,
    ftp_info: '',
    max_players: 0,
    rcon_info: '',
    rcon_password: '',
  },
});

export const isGameServerRunning = (gameStatus: ServerStatusType) =>
  gameStatus === ServerStatusType.RUNNING;
export const isGameServerAvailable = (gameStatus: ServerStatusType) =>
  gameStatus === ServerStatusType.AVAILABLE;
export const isGameServerUnavailable = (gameStatus: ServerStatusType) =>
  gameStatus === ServerStatusType.UNAVAILABLE;
export const isGameCanStart = (gameStatus: ServerStatusType) =>
  ServerStatusType.STOPPED === gameStatus ||
  ServerStatusType.ERROR === gameStatus ||
  ServerStatusType.RETRY === gameStatus ||
  ServerStatusType.DISABLED === gameStatus;
