let externalLibrariesLoaded = false;

export const getExternalLibrariesWithCookie = () => {
  // because we would like to load it only once
  if (externalLibrariesLoaded) {
    return;
  }

  externalLibrariesLoaded = true;
  // Hacked google tag manager and HotJar
  // @ts-ignore
  // window.dataLayer = window.dataLayer || [];
  // // @ts-ignore
  // // eslint-disable-next-line func-names, wrap-iife, security/detect-object-injection
  // (function (w, d, s, l, i) {
  //   // eslint-disable-next-line no-debugger
  //   debugger;
  //   // @ts-ignore
  //   w[l] = w[l] || [];
  //   // @ts-ignore
  //   w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  //   const f = d.getElementsByTagName(s)[0];
  //   const j = d.createElement(s);
  //   // eslint-disable-next-line eqeqeq
  //   const dl = l != 'dataLayer' ? `&l=${l}` : '';
  //   // @ts-ignore
  //   j.async = true;
  //   // @ts-ignore
  //   j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  //   // @ts-ignore
  //   f.parentNode.insertBefore(j, f);
  //   // @ts-ignore
  // })(window, document, 'script', 'dataLayer', );

  const j = document.createElement('script');
  const f = document.getElementsByTagName('script')[0];
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtag/js?id=${window.gtm_id}`;

  if (f) {
    f.parentNode?.insertBefore(j, f);
  }

  window.dataLayer = window.dataLayer || [];

  // eslint-disable-next-line func-names
  window.gtag = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };

  // @ts-ignore
  gtag('js', new Date());

  // @ts-ignore
  gtag('config', window.gtm_id);
};

export const detectRobot = (userAgent: string): boolean => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const robots = new RegExp(
    (
      [
        /bot/,
        /spider/,
        /crawl/, // GENERAL TERMS
        /APIs-Google/,
        /AdsBot/,
        /Googlebot/, // GOOGLE ROBOTS
        /mediapartners/,
        /Google Favicon/,
        /FeedFetcher/,
        /Google-Read-Aloud/,
        /DuplexWeb-Google/,
        /googleweblight/,
        /bing/,
        /yandex/,
        /baidu/,
        /duckduck/,
        /yahoo/, // OTHER ENGINES
        /ecosia/,
        /ia_archiver/,
        /facebook/,
        /instagram/,
        /pinterest/,
        /reddit/, // SOCIAL MEDIA
        /slack/,
        /twitter/,
        /whatsapp/,
        /youtube/,
        /semrush/, // OTHER
      ] as RegExp[]
    )
      .map((r) => r.source)
      .join('|'),
    'i',
  );

  return robots.test(userAgent);
};

export default getExternalLibrariesWithCookie;
