import { useToast as useChakraToast } from '@chakra-ui/react';

export const ToastType: { [key: string]: IToastType } = {
  error: {
    status: 'error',
    variant: 'gx-error',
  },
  info: {
    status: 'info',
    variant: 'gx-info',
  },
};

interface IToastType {
  status: 'error' | 'info' | 'warning' | 'success' | 'loading';
  variant: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useToast = () => {
  const toast = useChakraToast();

  return (message: string, type: IToastType = ToastType.error, timeout = 3000) => {
    toast({
      title: message,
      status: type.status,
      duration: timeout,
      isClosable: true,
      position: 'top',
      variant: type.variant,
    });
  };
};
