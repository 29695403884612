import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Link,
  Radio,
  RadioGroup,
  SimpleGrid,
  Switch,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Page } from '../../components/page/Page';
import {
  defaultLocation,
  IServerLocation,
  isLocationLabelBottom,
  Locations,
} from '../../models/ServerLocation';
import {
  IGame,
  IGameOptions,
  MinecraftJavaOptions,
  ServerStatusType,
} from '../../models/ServerType';
import { ProgressGlowButton } from '../../components/progress_button/ProgressGlowButton';
import { MinecraftJavaOptionsComponent } from '../../components/game_options/MinecraftJavaOptionsComponent';
import { getGame, MINECRAFT_JAVA_ID } from '../../models/GameTypes';
import { ROUTES } from '../../utils/routes';
import {
  useCreateServerMutation,
  useCreateServerProgressQuery,
} from '../../api/userServersApi';
import { useAuthContext } from '../../context/AuthorizationContext';
import { Error404Page } from '../errors/Error404Page';
import './CreateServerPage.scss';
import { ToastType, useToast } from '../../hooks/toast';
import { Analytics, GAEvent, GAEventType } from '../../utils/Analytics';

export const CreateServerPage = () => {
  const { t } = useTranslation('create_server_page');
  const { user } = useAuthContext();
  const { id: gameId } = useParams();
  const [createServer, { isLoading }] = useCreateServerMutation();
  const [progress, setProgress] = useState<number>(0);
  const [log, setLastLog] = useState<string[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [serverId, setServerId] = useState<string>('');
  const [error, setError] = useState<string>('');
  const toast = useToast();
  const { data: createProgressInfo, refetch } = useCreateServerProgressQuery(serverId, {
    skip: !inProgress,
  });
  const availableGames = user?.subscription?.game_types;
  const gameType = getGame(gameId || '');

  useEffect(() => {
    if (createProgressInfo) {
      const currentStep = createProgressInfo.steps.find(
        (step) => step.step_id === createProgressInfo.current_step_id,
      );

      if (currentStep) {
        setProgress(currentStep.progress / 100);

        if (currentStep.last_log !== log[log.length - 1]) {
          const newLog = [...log, currentStep.last_log];

          if (createProgressInfo.status === ServerStatusType.ERROR) {
            setError(currentStep.last_log);
            toast(currentStep.last_log, ToastType.errror, 30000); // 30s
          }

          setLastLog(newLog);
        }
      }

      if (
        createProgressInfo.status === ServerStatusType.COMPLETED &&
        createProgressInfo.total_steps === createProgressInfo.steps.length
      ) {
        window.location.href = ROUTES.GAMES;
      }
    }
  }, [createProgressInfo]);

  useEffect(() => {
    if (error === '' && createProgressInfo) {
      const ti = setTimeout(() => {
        refetch();
      }, 1000);

      return () => {
        clearTimeout(ti);
      };
    }

    return () => {};
  });

  const getGameOptionsComponent = (game: IGame, disabled: boolean) => {
    switch (game.id) {
      case MINECRAFT_JAVA_ID:
        return <MinecraftJavaOptionsComponent disabled={disabled} />;
      default:
        return <></>;
    }
  };

  const onSubmit = async (
    values: IGameOptions,
    setSubmitting: (status: boolean) => void,
  ) => {
    try {
      const response = await createServer(values).unwrap();
      Analytics.getInstance().sendEvent(GAEvent(GAEventType.serverCreate));

      if (response.id) {
        setServerId(response.id);
        setInProgress(true);
        setSubmitting(true);
      }
    } catch (e) {}
  };

  const getInitialValues = (game: IGame) => {
    const values: IGameOptions = {
      start_game: true,
      game_datacenter: defaultLocation!.id.toString(10),
      game_id: game.id,
      options: {},
    };

    const { options } = values;

    switch (game.id) {
      case MINECRAFT_JAVA_ID:
        Object.keys(MinecraftJavaOptions).forEach((el) => {
          options[el.toString()] = '1';
        });

        break;
      default:
    }

    values.options = options;
    return values;
  };

  if (!gameType) {
    return <></>;
  }

  const getRadioStyles = (location: IServerLocation) => {
    const useMap = useBreakpointValue({
      base: false,
      xs: true,
    });

    if (useMap) {
      return {
        left: location.map_x,
        top: location.map_y,
      };
    }

    return {};
  };

  if (
    availableGames &&
    gameId &&
    Array.isArray(availableGames) &&
    !availableGames.find((availableGame) => availableGame.id === gameId)
  ) {
    return <Error404Page />;
  }

  return (
    <Page translations="create_server_page" className="create_server_page">
      <Formik
        onSubmit={(values: IGameOptions, { setSubmitting }: FormikHelpers<any>) => {
          onSubmit(values, setSubmitting);
        }}
        initialValues={getInitialValues(gameType)}>
        {(props) => (
          <Form>
            <Field name="game_type">
              {({ field }: any) => (
                <FormControl {...field}>
                  <Input type="hidden" />
                </FormControl>
              )}
            </Field>
            <div className="container">
              <div className="inner">
                <p>{gameType.name}</p>
                <h3>{t('page.title')}</h3>
                <h5>{t('page.choose_location')}</h5>
                <div className="map">
                  <Field name="game_datacenter">
                    {({ field }: any) => (
                      <FormControl {...field} isDisabled={props.isSubmitting}>
                        <RadioGroup name="game_datacenter" defaultValue={field.value}>
                          {Locations.map((location) => (
                            <div
                              className={`location_point   ${
                                isLocationLabelBottom(location.id) ? 'bottom' : ''
                              }`}
                              style={getRadioStyles(location)}
                              key={location.id}>
                              <Radio variant="map" value={location.id.toString(10)}>
                                <div className="line" />
                                <div className="label">{location.name}</div>
                              </Radio>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                </div>
                <SimpleGrid columns={2} spacing={10} className="options">
                  {getGameOptionsComponent(gameType, props.isSubmitting)}
                </SimpleGrid>
                <SimpleGrid columns={1} spacing={10} className="start_game">
                  <Field name="start_game">
                    {({ field }: any) => (
                      <FormControl
                        {...field}
                        display="flex"
                        alignItems="center"
                        isDisabled={props.isSubmitting}>
                        <FormLabel htmlFor="start_game">{t('page.start_game')}</FormLabel>
                        <Switch id="start_game" isChecked={field.value} />
                      </FormControl>
                    )}
                  </Field>
                </SimpleGrid>
                <Container className="logs">
                  {log &&
                    Array.isArray(log) &&
                    log.map((l) => l && <li key={`l_${l}`}>{l}</li>)}
                </Container>
                <Container className="buttons">
                  {!props.isSubmitting && (
                    <Link as={ReactLink} to={ROUTES.GAMES}>
                      <Button variant="ghost">{t('page.button_back')}</Button>
                    </Link>
                  )}
                  {!isLoading && !inProgress && (
                    <Button type="submit">{t('page.button_start')}</Button>
                  )}
                  {(isLoading || inProgress) && (
                    <ProgressGlowButton progress={progress} />
                  )}
                </Container>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateServerPage;
