import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store';
import { AuthProvider } from './context/AuthorizationContext';
import '@fontsource/chakra-petch';
import '@fontsource/chakra-petch/700.css';
import './styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/logout" element={<></>} />
        <Route
          path="*"
          element={
            <AuthProvider>
              <App />
            </AuthProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  </Provider>,
);
