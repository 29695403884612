import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link } from '@chakra-ui/react';
import { CurrencyType, IServerType, SubscriptionType } from '../../models/ServerType';
import './SubscriptionListElement.scss';
import { useAuthContext } from '../../context/AuthorizationContext';
import { AuthorizationModel } from '../../models/AuthorizationModel';
import { setInnerHtml } from '../../utils/html.utils';
import { ROUTES } from '../../utils/routes';
import { Analytics, GAEvent, GAEventType } from '../../utils/Analytics';

interface ISubscriptionBoxProps {
  server?: IServerType;
  accent?: boolean;
  subscriptionType: SubscriptionType;
}

export const SubscriptionListElement = ({
  server,
  subscriptionType,
  accent = false,
}: ISubscriptionBoxProps) => {
  const { t } = useTranslation('choose_servers');
  const { user } = useAuthContext();

  if (!server) {
    return <></>;
  }

  const { name, subscription_options: subscriptionOptions } = server;

  const subscriptionOption = subscriptionOptions.find(
    (option) => option.name === subscriptionType,
  );

  if (!subscriptionOption) {
    return <></>;
  }

  const isServerActive =
    user?.subscription?.servers &&
    user?.subscription?.servers.indexOf(server.slug) !== -1;

  const getPriceLabel = (price: any, currencyType: CurrencyType) => {
    if (currencyType === CurrencyType.USD) {
      return <>${price}</>;
    }

    return <>€{price}</>;
  };

  const games = server.game_types.map((game1) => game1.name).sort();

  const getSubscribeButton = () => {
    const paymentUrl: URL = new URL(subscriptionOption.payment_url);

    if (process.env.REACT_APP_REDIRECT_TO) {
      paymentUrl.searchParams.append('redirect-to', process.env.REACT_APP_REDIRECT_TO);
    }

    if (!AuthorizationModel.isActiveSubscription(user)) {
      return (
        <Link
          onClick={() => {
            Analytics.getInstance().sendEvent(GAEvent(GAEventType.subscriptionChoose));
          }}
          href={paymentUrl.href}
          className="nohover">
          <Button>{t('subscribe_button')}</Button>
        </Link>
      );
    }

    if (isServerActive) {
      return (
        <Link href={ROUTES.GAMES} className="nohover">
          <Button>
            <div {...setInnerHtml(t('my_games'))} />
          </Button>
        </Link>
      );
    }

    return <></>;
  };

  return (
    <div
      className={`subscription_list_item ${accent ? 'accent' : ''} ${
        isServerActive ? 'active' : ''
      }`}>
      <div className="inner">
        <div>
          <h3 className="server_name">{name}</h3>
          <div className="players">
            <div>{server.players}</div>
            {t('players')}
          </div>
          <div className="price_label">
            <h4>
              {getPriceLabel(subscriptionOption.price, subscriptionOption.currency)}
              <span>{`/${t('price_period')}`}</span>
            </h4>
            <div className="vat">{t('vat_included')}</div>
          </div>
        </div>
        <div className="apps">
          <span>{t('games_to_choose')}</span>

          <ul>
            {games.map((game) => (
              <li key={game}>{game}</li>
            ))}
          </ul>
        </div>

        <div className="subscriptions">{getSubscribeButton()}</div>
      </div>
    </div>
  );
};

export default SubscriptionListElement;
