import { FC, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { detectRobot, getExternalLibrariesWithCookie } from '../../utils/cookie.utils';
import { setInnerHtml } from '../../utils/html.utils';
import './CookieConsent.scss';

const CookieConsentComponent: FC = () => {
  const [isCookieConsentAccepted, setCookieConsentAccepted] = useState<boolean>(
    !!getCookieConsentValue(),
  );
  const { t } = useTranslation('cookie_consent');

  if (window && detectRobot(window.navigator.userAgent)) {
    return <></>;
  }

  if (isCookieConsentAccepted) {
    getExternalLibrariesWithCookie();
  }

  return !isCookieConsentAccepted ? (
    <>
      <div className="cookie-background" />
      <CookieConsent
        buttonText={t('button_label')}
        buttonClasses="chakra-button"
        buttonWrapperClasses=""
        containerClasses="cookie-consent"
        disableStyles
        expires={665}
        location="none"
        onAccept={(acceptedByScrolling) => {
          if (!acceptedByScrolling) {
            setCookieConsentAccepted(true);
          }
        }}>
        <h5>{t('title')}</h5>
        <p {...setInnerHtml(t('message'))} />
      </CookieConsent>
    </>
  ) : (
    <></>
  );
};

export default CookieConsentComponent;
