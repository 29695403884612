import React from 'react';
import { Center, Container, VStack } from '@chakra-ui/react';

export const Error404Page = () => (
  <Container height="80vh" className="error404_page">
    <Center>
      <VStack marginTop="30vh">
        <h1 className="error">404 not found</h1>
      </VStack>
    </Center>
  </Container>
);
export default Error404Page;
