import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import './HomeGameCarousel2.scss';
import GameTypes from '../../models/GameTypes';
import { Analytics, GAEvent, GAEventType } from '../../utils/Analytics';

interface ICarouselGame {
  image: string;
  name: string;
  logo: string;
}

export const HomeGameCarousel2 = () => {
  const [gameIndex, setGameIndex] = useState<number>(-1);
  const [games, setGames] = useState<ICarouselGame[]>([]);

  const rotateArray = (arr: ICarouselGame[], index: number) => {
    // Check if the array is empty or the index is 0
    if (arr.length === 0 || index === 0) {
      return arr;
    }

    // Calculate the effective index based on array length and given index
    const effectiveIndex = index % arr.length;

    // Slice the array based on the effective index and concatenate the two parts
    if (effectiveIndex >= 0) {
      return arr.slice(effectiveIndex).concat(arr.slice(0, effectiveIndex));
    }

    return arr.slice(effectiveIndex).concat(arr.slice(0, arr.length + effectiveIndex));
  };

  useEffect(() => {
    setGames(
      rotateArray(
        GameTypes.map((game) => ({
          image: `/assets/images/games/carousel/${game.id}.png`,
          name: game.name,
          logo: `/assets/images/games/carousel/${game.id}-logo.png`,
        })),
        gameIndex,
      ),
    );
  }, [gameIndex, setGames]);

  return (
    <div className="home-game-carousel2">
      <div className="carousel">
        <span
          className="left"
          onClick={() => {
            setGameIndex(gameIndex - 1);
            Analytics.getInstance().sendEvent(GAEvent(GAEventType.carousel2LeftClick));
          }}>
          <Image src="/assets/images/arrow-left.svg" />
        </span>
        {games.length > 0 && (
          <>
            <div className="game-secondary">
              <Image src={games[0].image} />
            </div>
            <div className="game-primary">
              <h2>
                {games[1].name}
                <Image src={games[1].logo} />
              </h2>
              <Image className="game-img" src={games[1].image} />
            </div>
            <div className="game-secondary">
              <Image src={games[2].image} />
            </div>
          </>
        )}
        <span
          className="right"
          onClick={() => {
            setGameIndex(gameIndex + 1);
            Analytics.getInstance().sendEvent(GAEvent(GAEventType.carousel2RightClick));
          }}>
          <Image src="/assets/images/arrow-right.svg" />
        </span>
      </div>
    </div>
  );
};

export default HomeGameCarousel2;
