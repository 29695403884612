import { defineStyleConfig } from '@chakra-ui/react';

export const Popover = defineStyleConfig({
  // parts:     ['label'],
  baseStyle: {
    // popper: {
    // },
    _focusVisible: {
      outline: 'none',
      boxShadow: 'none',
    },
    content: {
      width: '288px',
      outline: 'none',
      marginTop: '10px',
      borderColor: 'brand.ghost-light',
      borderWidth: '1px',
      borderRadius: '10px 0px 10px 10px',
      p: '20px',
      textAlign: 'center',
      color: 'white',
      bg:
        'radial-gradient(circle at 50% -60%, rgba(var(--chakra-colors-brand-ghost-light-n), 0.45) 20%, ' +
        'rgba(0, 0, 0, 0.40) 60%), ' +
        'rgba(var(--chakra-colors-brand-ghost-n), 0.85)',
      backdropFilter: 'blur(4px)',
    },
  },
  variants: {},
});

export default Popover;
