import './ProgressGlowButton.scss';

interface IProps {
  progress: number;
}

const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const ProgressGlowButton = ({ progress }: IProps) => {
  const percent = Math.round(clamp(Math.round(progress * 100), 0, 100));

  return (
    <button className="glow-progress" type="button">
      <span className="glows">
        <span className="spark__container spark__glows">
          <span className="spark" />
          <span className="spark" />
        </span>
      </span>
      <span className="spark__container">
        <span className="spark" />
        <span className="spark" />
      </span>
      <span className="text">{percent}%</span>
    </button>
  );
};
