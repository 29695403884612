import { Container, FormControl, Radio, RadioGroup } from '@chakra-ui/react';
import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { MinecraftJavaOptions } from '../../models/ServerType';

interface IProps {
  disabled: boolean;
}

export const MinecraftJavaOptionsComponent = ({ disabled }: IProps) => {
  const { t } = useTranslation('game_options');
  return (
    <>
      {Object.keys(MinecraftJavaOptions).map((optionName) => {
        // eslint-disable-next-line security/detect-object-injection
        const options = MinecraftJavaOptions[optionName];

        return (
          <Container key={optionName}>
            <Field name={`options.${optionName}`}>
              {({ field }: any) => (
                <FormControl {...field} isDisabled={disabled}>
                  <span>{t(`minecraft.${optionName}`)}</span>
                  <RadioGroup defaultValue={field.value} name={`options.${optionName}`}>
                    {Object.values(options).map((option) => (
                      <div key={option.id}>
                        <Radio value={option.id}>{option.name}</Radio>
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Field>
          </Container>
        );
      })}
    </>
  );
};

export default MinecraftJavaOptionsComponent;
