import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  list: {
    outline: 'none',
    // marginTop:      '10px',
    borderColor: 'brand.ghost-light',
    padding: '0.5rem',
    borderWidth: '1px',
    borderRadius: '10px 10px 10px 10px',
    textAlign: 'center',
    color: 'white',
    bg:
      'radial-gradient(circle at 50% -60%, rgba(var(--chakra-colors-brand-ghost-light-n), 0.45) 20%, rgba(0, 0, 0, 0.40) 60%),' +
      ' rgba(var(--chakra-colors-brand-ghost-n), 0.85)',
    backdropFilter: 'blur(4px)',
    filter:
      'drop-shadow(0px 0px 60px rgba(var(--chakra-colors-brand-ghost-light-n), 0.7))',
  },
});
// export the base styles in the component theme
export const Menu = defineMultiStyleConfig({ baseStyle });
export default Menu;
