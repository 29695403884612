import { Center, Container, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionType } from '../../models/ServerType';
import { useGetServerTypesQuery } from '../../api/servers';
import { Loader } from '../loader/Loader';
import { SubscriptionListElement } from './SubscriptionListElement';
import './ChooseSubscription.scss';
import { AuthorizationModel } from '../../models/AuthorizationModel';
import { useAuthContext } from '../../context/AuthorizationContext';

export const ChooseSubscription = () => {
  const { t } = useTranslation('choose_servers');
  const { user } = useAuthContext();
  const { data, isLoading } = useGetServerTypesQuery();

  if (isLoading) {
    return <Loader />;
  }

  const server1 = data ? data[0] : undefined;
  const server2 = data ? data[1] : undefined;
  const server3 = data ? data[2] : undefined;
  let server1accent = false;
  let server2accent = true;
  let server3accent = false;

  if (AuthorizationModel.isActiveSubscription(user) && user?.subscription?.servers) {
    if (server1) {
      server1accent = user?.subscription?.servers.indexOf(server1.slug) !== -1;
    }

    if (server2) {
      server2accent = user?.subscription?.servers.indexOf(server2.slug) !== -1;
    }

    if (server3) {
      server3accent = user?.subscription?.servers.indexOf(server3.slug) !== -1;
    }
  }

  return (
    <Container className="choose_servers" maxWidth="100%">
      <Center>
        <h2>{t('title')}</h2>
      </Center>

      <Center className="period">
        <SimpleGrid
          columns={{
            'base': 1,
            'xs': 3,
            'sm': 3,
            'md': 3,
            'lg': 3,
            'xl': 3,
            '2xl': 3,
          }}
          alignItems="flex-start"
          spacing="0">
          <Container className="badge_icon">
            <span />
          </Container>
        </SimpleGrid>
      </Center>

      <Center>
        <SimpleGrid
          columns={{
            'base': 1,
            'xs': 3,
            'sm': 3,
            'md': 3,
            'lg': 3,
            'xl': 3,
            '2xl': 3,
          }}
          alignItems="flex-start"
          spacing="10"
          className="subscription_list"
          justifyItems="center">
          <SubscriptionListElement
            key="serv1"
            server={server1}
            subscriptionType={SubscriptionType.monthly}
            accent={server1accent}
          />
          <SubscriptionListElement
            key="serv2"
            server={server2}
            subscriptionType={SubscriptionType.monthly}
            accent={server2accent}
          />
          <SubscriptionListElement
            key="serv3"
            server={server3}
            subscriptionType={SubscriptionType.monthly}
            accent={server3accent}
          />
        </SimpleGrid>
      </Center>
    </Container>
  );
};

export default ChooseSubscription;
