import React from 'react';
import { Container, Image, SimpleGrid } from '@chakra-ui/react';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/page/Page';
import { ChooseSubscription } from '../../components/choose_subscription/ChooseSubscription';
import { ROUTES } from '../../utils/routes';
import { HomeGameCarousel2 } from '../../components/home_carousel_2/HomeGameCarousel2';
import { HomeGameCarousel } from '../../components/home_carousel/HomeGameCarousel';
import './NewHomePage.scss';
import { setInnerHtml } from '../../utils/html.utils';

export const NewHomePage = () => {
  const { t } = useTranslation('home_page_2');

  const getSectionColumns = () => ({
    'base': 1,
    'xs': 2,
    'sm': 2,
    'md': 2,
    'lg': 2,
    'xl': 2,
    '2xl': 2,
  });

  const getGameStep = (i: number) => (
    <div className={`steps step${i}`}>
      <h5>{t(`page.section3.step${i}.title`)}</h5>
      <h4>{t(`page.section3.step${i}.subtitle`)}</h4>
      <div className="outer">
        <Image src={`/assets/images/home-2/section3-step${i}.png`} />
      </div>
      <p>{t(`page.section3.step${i}.paragraph`)}</p>
    </div>
  );
  return (
    <Page translations="home_page" className="home_2_page">
      <Image className="bg-left" src="/assets/images/home-2/bg-left.svg" />
      <Image className="bg-right" src="/assets/images/home-2/bg-right.svg" />

      <SimpleGrid columns={1} spacing={0} className="content" justifyItems="center">
        <SimpleGrid className="section section1" width="100%">
          <Image
            className="header-bg-dots"
            src="/assets/images/home-2/header-dots-1.svg"
          />
          <Image
            className="header-bg-lines"
            src="/assets/images/home-2/header-lines-1.svg"
          />
          <Element className="anchor" name={ROUTES.HOME} />
          <h1>{t('page.section1.header')}</h1>
        </SimpleGrid>

        <SimpleGrid columns={5} spacing={0} className="section section2">
          <HomeGameCarousel />
          <div className="section2-dots" />
        </SimpleGrid>
        <hr className="section2-hr" />
        <Element name={ROUTES.HOW_IT_WORKS} />
        <h2>{t('page.section3.header')}</h2>

        <SimpleGrid
          columns={{
            'base': 1,
            'xs': 1,
            'sm': 1,
            'md': 4,
            'lg': 4,
            'xl': 4,
            '2xl': 4,
          }}
          spacing={0}
          className="section section3">
          {getGameStep(1)}
          {getGameStep(2)}
          {getGameStep(3)}
          {getGameStep(4)}
        </SimpleGrid>

        <h2 className="section4-header">
          <Image
            className="section4-dots"
            src="/assets/images/home-2/section4-dots.svg"
          />
          {t('page.section4.header')}
          <span>{t('page.section4.subheader')}</span>
        </h2>

        <SimpleGrid
          columns={getSectionColumns()}
          spacing={0}
          className="section section5">
          <HomeGameCarousel2 />
          <div className="bg" />
        </SimpleGrid>

        <SimpleGrid columns={1} className="section section6" width="100%">
          <Element name={ROUTES.SUBSCRIPTION} />
          <ChooseSubscription />
        </SimpleGrid>

        <SimpleGrid
          columns={getSectionColumns()}
          spacing={0}
          className="section section7"
          width="100%"
          alignItems="center">
          <Container>
            <h2>{t('page.section7.header')}</h2>
            <p {...setInnerHtml(t('page.section7.paragraph'))} />
          </Container>
          <Container className="image">
            <Image margin="auto" src="/assets/images/home-2/section7-img.png" />
          </Container>
        </SimpleGrid>
      </SimpleGrid>
    </Page>
  );
};

export default NewHomePage;
