export interface IServerLocation {
  id: number;
  name: string;
  map_x: number;
  map_y: number;
}

export const AMSTERDAM_LOCATION_ID = 1;
export const DEFAULT_LOCATION_ID = AMSTERDAM_LOCATION_ID;
export const PARIS_LOCATION_ID = 4;
export const isLocationLabelBottom = (id: number) => id === PARIS_LOCATION_ID;
export const Locations: IServerLocation[] = [
  {
    id: 6,
    name: 'Oregon',
    map_x: 100,
    map_y: 150,
  },
  {
    id: 5,
    name: 'Dallas',
    map_x: 160,
    map_y: 160,
  },
  {
    id: 3,
    name: 'New York',
    map_x: 200,
    map_y: 150,
  },
  {
    id: 8,
    name: 'London',
    map_x: 370,
    map_y: 95,
  },
  {
    id: PARIS_LOCATION_ID,
    name: 'Paris',
    map_x: 382,
    map_y: 118,
  },
  {
    id: AMSTERDAM_LOCATION_ID,
    name: 'Amsterdam',
    map_x: 395,
    map_y: 105,
  },
  {
    id: 2,
    name: 'Frankfurt',
    map_x: 400,
    map_y: 120,
  },
  {
    id: 7,
    name: 'Sydney',
    map_x: 705,
    map_y: 325,
  },
];
export const defaultLocation = Locations.find((loc) => loc.id === DEFAULT_LOCATION_ID);
